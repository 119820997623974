<script setup lang="ts">
import { TransitionRoot } from "@headlessui/vue";

const { closeBtn = false, backgroundClass } = defineProps<{
  closeBtn?: boolean;
  backgroundClass?: string;
}>();
const emit = defineEmits(["close"]);

const minimized = ref(false);
</script>

<template>
  <UCard
    class="mb-1 flex-shrink flex-grow-0 bg-slate-100"
    :ui="{ body: 'p-0' }"
    :class="{ [backgroundClass!]: true }"
  >
    <div
      class="sticky top-0 z-10 flex h-8 items-center justify-between gap-2 rounded-t-lg border-b border-b-gray-400 bg-slate-100"
      :class="{ 'border-none': minimized, [backgroundClass!]: true }"
    >
      <div class="flex flex-1 justify-start">
        <div class="flex gap-1 px-1 sm:px-4"><slot name="title" /></div>
      </div>
      <UButton
        v-if="closeBtn"
        class="px-1"
        title="Close"
        size="xs"
        :padded="false"
        variant="ghost"
        color="gray"
        icon="i-heroicons-x-mark"
        @click="emit('close')"
      ></UButton>
      <UButton
        class="px-4"
        title="Minimize/Uminimize"
        size="xs"
        :padded="false"
        variant="ghost"
        color="gray"
        icon="i-heroicons-chevron-down"
        @click="minimized = !minimized"
      ></UButton>
    </div>

    <TransitionRoot
      enter="transition-opacity duration-300"
      enter-from="opacity-0"
      enter-to="opacity-100"
      as="div"
      :show="!minimized"
      class="px-1 pb-4 sm:px-4"
    >
      <slot />
    </TransitionRoot>
  </UCard>
</template>
