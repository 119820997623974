<script setup lang="ts">
import { TransitionRoot } from "@headlessui/vue";

const { id = "sidebar", modelValue: _open } = defineProps<{
  id?: string;
  modelValue: boolean;
}>();

const emit = defineEmits<{ "update:modelValue": [value: boolean] }>();

const open = computed({
  get() {
    return _open;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>

<template>
  <TransitionRoot
    as="div"
    :show="open"
    :id="id"
    :unmount="false"
    enter="transition-transform duration-150"
    enter-from=" -translate-x-full"
    enter-to=" translate-x-0"
    leave="transition-transform duration-150"
    leave-from=" translate-x-0"
    leave-to=" -translate-x-full"
    class="relative flex h-full min-w-[370px] flex-col justify-start overflow-hidden bg-gray-50 pt-10 shadow ring-1 ring-gray-200 transition-all duration-300 sm:min-w-[425px]"
  >
    <UButton
      class="absolute right-2 top-2 p-1"
      color="gray"
      title="hide tool bar"
      @click="open = false"
      :padded="false"
      variant="ghost"
      icon="i-mdi-arrow-collapse-left"
      label="Hide"
    />
    <slot />
  </TransitionRoot>
</template>
