export const extensions = [
  "pec",
  "pes",
  "exp",
  "dst",
  "jef",
  "vp3",
  "svg",
  "csv",
  "xxx",
  "sew",
  "u01",
  "shv",
  "10o",
  "100",
  "bro",
  "dat",
  "dsb",
  "dsz",
  "emd",
  "exy",
  "fxy",
  "gt",
  "inb",
  "tbf",
  "ksm",
  "tap",
  "spx",
  "stx",
  "phb",
  "phc",
  "new",
  "max",
  "mit",
  "pcd",
  "pcq",
  "pcm",
  "pcs",
  "jpx",
  "stc",
  "zhs",
  "zxy",
  "pmv",
  "png",
  "txt",
  "gcode",
  "hus",
  "edr",
  "col",
  "inf",
  "json",
];
