export function hasKey<O extends object>(
  obj: O,
  key: keyof any,
): key is keyof O {
  return key in obj;
}

export function hasNoKey<O extends object>(obj: O, key: keyof any): boolean {
  return !(key in obj);
}

export function humanReadableFileSize(nBytes: number): string {
  const aMultiples = ["bytes", "KB", "MB", "GB", "TB"];
  const nMultiple = Math.floor(Math.log(nBytes) / Math.log(1024));
  return `${(nBytes / Math.pow(1024, nMultiple)).toFixed(2)} ${
    aMultiples[nMultiple]
  }`;
}

export type OrMore<T extends unknown[]> = [...T, ...any[]];

export async function readBufferFromFile(file: File) {
  const reader = new FileReader();
  const promise = new Promise<Uint8Array>((resolve, reject) => {
    reader.onload = function () {
      const arrayBuffer = this.result;
      if (arrayBuffer == null || !(arrayBuffer instanceof ArrayBuffer)) {
        reject("reading array buffer failed");
      } else {
        const array = new Uint8Array(arrayBuffer);
        resolve(array);
      }
    };
  });
  reader.readAsArrayBuffer(file);
  return promise;
}
