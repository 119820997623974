<script setup lang="ts">
import { loadPyodide, type PyodideInterface } from "pyodide";
import { extensions } from "~/utils/emb/formasts";
import type { Pattern } from "~/utils/emb/pattern";
import readEmb from "~/utils/read_emb.py?raw";

interface PatternFull {
  patternData: Pattern;
  size: number;
}

const sidebar = ref(true);
const mountPattern = ref(false);
const patternFull = ref<PatternFull>();
const previewPattern = computed(() => patternFull.value?.patternData);
const loadingPattern = ref(false);
const previewPatternHumanSize = computed(() => {
  const nbytes = patternFull.value?.size;
  return nbytes && humanReadableFileSize(nbytes);
});
const previewMetadata = ref<{ name: string }>();
const pyodide = shallowRef<PyodideInterface>();
const inputFile = ref<File>();
const uploading = ref(false);

onMounted(async () => {
  mountPattern.value = true;
});

function onInputChange(e: Event) {
  const target = e.currentTarget as HTMLInputElement;
  if (target && target.files && target.files.length > 0) {
    inputFile.value = target.files[0];
  }
}

async function uploadFile() {
  uploading.value = true;

  if (!inputFile.value) {
    console.error("No file selected");
    return;
  }
  if (!pyodide.value) {
    const _pyodide = await loadPyodide();
    await _pyodide.loadPackage("/pyembroidery.whl");
    pyodide.value = _pyodide;
  }

  const readEmbFromBuffer = await pyodide.value.runPythonAsync(readEmb, {
    filename: "read_emb.py",
  });
  const size = inputFile.value.size;
  const name = inputFile.value.name;
  const buffer = await readBufferFromFile(inputFile.value);
  const patternJSON = readEmbFromBuffer(buffer, name);
  const pattern = JSON.parse(patternJSON);
  previewMetadata.value = { name };

  patternFull.value = {
    patternData: pattern,
    size,
  };
  uploading.value = false;
}
</script>

<template>
  <div class="flex h-screen flex-col overflow-hidden">
    <PageHeaderBar />
    <div class="flex h-full flex-shrink overflow-hidden">
      <UButton
        class="relative z-10 -ml-[68px] opacity-30 transition-all duration-300 ease-in-out hover:translate-x-2/3 hover:opacity-100 focus:translate-x-2/3 focus:opacity-100 active:translate-x-2/3 active:opacity-100"
        v-if="!sidebar"
        color="gray"
        variant="ghost"
        icon="i-mdi-arrow-expand-right"
        @click="sidebar = true"
        label="Tool bar"
        title="show tool bar"
        trailing
      />
      <ControlSideBar v-model="sidebar">
        <ControlCard class="overflow-auto">
          <template #title>Files</template>
          <div id="upload-file-section" class="mb-4 mt-2 max-w-md">
            <label
              class="mb-2 block text-sm font-medium text-gray-900"
              for="file_input"
              >Add a file</label
            >
            <UButtonGroup>
              <input
                ref="fileInputEl"
                class="file:bg-primary-500 hover:file:bg-primary-700 block w-full rounded-md border text-sm file:mr-4 file:rounded-md file:border-0 file:px-4 file:py-2.5 file:text-sm file:font-semibold file:text-white focus:outline-none disabled:pointer-events-none disabled:opacity-60"
                aria-describedby="file_input_help"
                @change="onInputChange"
                id="file_input"
                type="file"
                :accept="extensions.map((ext) => '.' + ext).join(',')"
              />
              <UButton
                :loading="uploading"
                :disabled="!inputFile"
                label="Load"
                variant="outline"
                @click="uploadFile"
              >
                <template #trailing v-if="!!inputFile"
                  ><span class="relative flex h-3 w-3">
                    <span
                      class="absolute inline-flex h-full w-full animate-ping rounded-full bg-cyan-300 opacity-75"
                    ></span>
                    <span
                      class="relative inline-flex h-3 w-3 rounded-full bg-cyan-400"
                    ></span> </span
                ></template>
              </UButton>
            </UButtonGroup>

            <p
              class="mt-1 block w-full overflow-clip overflow-ellipsis text-sm text-gray-500 [text-wrap:nowrap] dark:text-gray-300"
              id="file_input_help"
              :title="extensions.map((ext) => ext.toUpperCase()).join(`, `)"
            >
              {{ extensions.map((ext) => ext.toUpperCase()).join(", ") }}
            </p>
          </div>
        </ControlCard>
      </ControlSideBar>
      <LazyPatternSVGViewer
        v-if="!!previewPattern && mountPattern"
        :pattern="previewPattern"
        :patternSize="previewPatternHumanSize"
        :metadata="previewMetadata"
        :close-btn="false"
      />
      <FullLoading v-else-if="loadingPattern" />
    </div>
  </div>
</template>
